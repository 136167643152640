.grid-container-wrapper {
  max-width: 100%;
  max-height: calc(100vh - 20vh);
  overflow: auto;
}

.grid-container {
  display: grid;
  grid-gap: 1.75rem;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(20, 30px); */
  grid-auto-rows: auto;
  margin: 0 auto;
  max-width: 85%;
  padding-bottom: 20px;
}

.grid-item {
  display: flex; /* Set display to flex */
  align-items: center; /* Align items vertically */
  justify-content: center;
  background-color: silver;
  color: white;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  padding: 10px;
  border-radius: 10px;
  background-size: cover; /* or contain */
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}


  .grid-item:hover {
    transform: translateY(15px);
  }
  .grid-item:hover:before {
    opacity: 1;
  }
  .grid-item:hover .info {
    opacity: 1;
    transform: translateY(0px);
  }
  .grid-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }

  .grid-item .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
  }
  .grid-item .info h1 {
    margin: 0px;
    font-size: 38px;
  }
  .grid-item .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
  }
  .grid-item .info button {
    padding: 1rem;
    width: 50%;
    outline: none;
    border: none;
    border-radius: 3px;
    background: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .grid-item .info button:hover {
    background: #64AFE9;
    color: white;
  }