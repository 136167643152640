:root {
    --background-color: black;
    --text-color: hsl(0, 0%, 90%);
  }

.header-title-wrapper {
  display: grid;
  place-content: center;
  min-height: 20vh;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1.5rem, 1rem + 7vw, 15rem);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-color);
}

.header-title-wrapper > div {
  grid-area: 1/1/-1/-1;
}

.top {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
  }
  .bottom {
    clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
    color: transparent;
    background: -webkit-linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background: linear-gradient(177.5deg, black 53%, var(--text-color) 65%);
    background-clip: text;
    -webkit-background-clip: text;
    transform: translateX(-0.02em);
  }
  