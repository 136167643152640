.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .not-found>h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 18rem;
    color: #6a1717;
    margin-bottom: 0;
  }
  
  .not-found>p {
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    color: silver;
    margin-top: 20px;
    text-align: center;
  }
  