body, html, #root {
    margin: 0;
    padding: 0;
  }

  .reviewItem-wrapper {
    position: relative; /* Ensure proper stacking context */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    max-height: calc(100vh - 70px); /* Adjust as needed, considering Navbar height */
  }

  .review-box {
    padding: 30px;
    overflow: scroll;
  }

  .review-item {
    margin-bottom: 30px; /* Add more space between review items */
  }