.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #6a1717 100%);
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  user-select: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 1;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
}

.navbar-logo {
  font-family: 'Montserrat', sans-serif;
  padding: 5px;
  color: white; /* Adjust text color as needed */
  text-decoration: none;
  font-size: 36px; /* Adjust font size as needed */
  align-items: center;
}

.sort-icon {
  font-size: 28px;
  padding: 15px;
  cursor: pointer;
}

.sortingOrder-icon {
  margin-left: 5px; /* Adjust spacing between text and icon as needed */
}

.rotated-clockwise {
  transform: rotate(0deg);
  transition: transform 0.7s ease;
}

.rotated-counterclockwise {
  transform: rotate(-180deg);
  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}



.nav-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 65px;
  right: 12px;
  background-color: #333;
  width: 15vw;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  list-style-type: none;
}

.nav-menu.active {
  display: flex;
}

.nav-item {
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.nav-item:hover {
  background-color: #a0a0a0;
  
}

.item-content {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sorting-order-icon i {
  font-size: 22px;
  transition: transform 0.3s ease;
}

.sorting-order-icon.rotate-up i {
  transform: rotate(0deg);
}

.sorting-order-icon.rotate-down i {
  transform: rotate(180deg);
}
