.info-container {
    position: fixed;
    display: inline-block;
    z-index: 9999;
    bottom: 5px;
    right: -7px;
    font-family: 'Poppins', sans-serif;
  }
  
  .info-icon {
    color: #64AFE9;
    font-size: 30px;
    padding: 5px;
    width: 38px;
    height: 38px;
  }
  
  .info-box {
    position: absolute;
    bottom: -2px;
    right: 0px;
    width: 220px;
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-in-out;
    font-size: 14px;
    line-height: 18px;
  
    background: rgba(50, 50, 50, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  
  #copyButton {
    color: #64AFE9;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px;
    padding-top: 3px;
    font-size: 14px;
  }
  
  .info-container:hover .info-box {
    opacity: 1;
    visibility: visible;
  }
  