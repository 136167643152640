html,
body {
    margin: 0;
    padding: 0;
    touch-action: none;
    user-select: none;
    -ms-content-zooming: none;
    -ms-touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

canvas {
    display: block;
    touch-action: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
