.review-item {
    border: 2px solid #dddddd;
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.24);
    display: flex;
    flex-direction: column;
  }

  .folded {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  }
  
  .review-title {
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 10px;
    color: white;
  }

  .rating-wrapper {
    height: 20px;
    width: 50vw;
    border: 2px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: auto;
  }

  .rating {
    background: linear-gradient(to right, #e74c3c, #f8d568, #4caf50);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    height: 20px;
  }
  
  .score {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #A61717;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0%, -50%);
  }
  
  .expand-icon {
    font-size: 48px;
    color: #fff;
    cursor: pointer;
    transition: transform 0.6s ease; /* Add a transition for smooth icon changes */
    transform-origin: center; /* Set the transform origin to the center for a smooth flip effect */
  }
  
  .expand-icon.flip {
    transform: scaleY(-1); /* Flip horizontally */
  }


  /* BELOW IF FOR WHEN REVIEW ITEM IS EXPANED: */

  .unfolded {
    display: flex;
    margin-top: 15px;

    border-top: 1px solid rgba(221, 221, 221, 0.3);

    font-family: 'Montserrat', sans-serif;
  }
  
  .description,
  .graph {
    padding: 50px;
    flex: 1;
    color: #ddd;
  }

  .description {
    padding: 50px;
    margin-right: 20px;
    border-right: 1px solid rgba(221, 221, 221, 0.3);
    position: relative;
    line-height: 1.8;
  }

  .date {
    position: absolute; /* Position the date absolutely */
    bottom: 20px; /* Adjust as per your preference */
    left: 50%;
    transform: translate(-50%);
    font-style: italic;
    font-size: 16px;
    color: #aaa;
  }